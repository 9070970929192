.react-datepicker {
  border: none;
  border-radius: 12px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
  padding: 12px;
}
.react-datepicker__header {
  background-color: #ffffff;
}
.react-datepicker * {
  font-family: 'Noto Scans KR';
}
.react-datepicker__day--keyboard-selected {
  background-color: #246cf6;
  border-radius: 50%;
}
.react-datepicker__day:hover {
  border-radius: 50%;
}
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  border-bottom-color: white;
  left: -200px;
}
.react-datepicker__triangle::before {
  display: none;
}
.react-datepicker__navigation {
  margin-top: 12px;
}
